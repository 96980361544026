import auth from "@/utils/auth";

const axiosModule = require("axios").default;

/**
 * Get axios instance without authentication.
 */
export const axiosUnAuth = () => {
  return axiosModule.create({ baseURL: process.env.VUE_APP_API_URL });
};

/**
 * Get axios instance authenticated.
 */
export const axios = () => {
  const token = auth.getToken();
  return axiosModule.create({
    baseURL: process.env.VUE_APP_API_URL,
    headers: { Authorization: `Bearer ${token}` }
  });
};

export default { axiosUnAuth, axios };
